<script setup>
import {computed} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

const props= defineProps({
    type: {
        type: String,
        default: 'submit',
    },
	textSize: {
			type: String,
			default: 'text-xs'
	},
	padding: String,
	hexBgColor: String,
	hexTextColor: String
});

const page = usePage();
const textColor = computed(() => props.hexTextColor ?? page.props.value.textColor);
const primaryColor = computed(() => props.hexBgColor ?? page.props.value.primaryColor);

</script>

<template>
    <button :type="type"
            :style="['color:'+ textColor, 'background-color:'+primaryColor]"
            :class="[textSize, padding ?? ' px-4 py-2']"
            class="rounded-full justify-center inline-flex items-center border border-transparent font-semibold uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition">
        <slot />
    </button>
</template>
